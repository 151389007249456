import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import { connect } from "react-redux";
import FilterPanel from "../../Components/FilterPanel";
import ItemBrowser from "../../Components/ItemBrowser";
import GridItem from "../../Components/ProductGridItem";
import ListItem from "../../Components/ProductListItem";
import Paging from "../../Components/Paging";
import Sorting from "../../Components/Sorting";
import Searching from "../../Components/Searching";
import Breadcrumbs from "../../Components/Breadcrumbs";
import AppConfig from "../../Config";
import labels from "../../Domain/CategoryTree";

class ProductListingPage extends Component {
  render() {
    let Item =
      AppConfig.productListing.layoutType === "grid" ? GridItem : ListItem;
    let { categoryId, appActions, navActions } = this.props;

    return (
      <Wrapper>
        <div className="products-filter">
          <FilterPanel
            labels={labels}
            activeFilter={categoryId}
            onFilterItemClick={updateLabel =>
              navActions.push(`/products/${updateLabel}`)
            }
          />
        </div>

        <ItemBrowser
          label={categoryId}
          navPush={navActions.push}
          limit={4}
          fetchItems={appActions.fetchProducts}
        >
          {({ mounted, loading, data, page, sort, search }) => {
            return (
              <div className="products-content">
                <div className="products-breadcrumbs">
                  <Breadcrumbs pathPrefix="/products" label={categoryId} />
                </div>

                <div className="products-toolbar">
                  <div className="products-search">
                    <Searching search={search} />
                  </div>

                  {sort && (
                    <div className="products-sort">
                      <Sorting sort={sort} />
                    </div>
                  )}
                </div>

                <div className="products-collection">
                  {data &&
                    data.map(d => (
                      <Item
                        onClick={() => navActions.push(`/product/?id=${d.id}`)}
                        key={d.id}
                        item={d}
                      />
                    ))}
                </div>

                {page.pages && (
                  <div className="products-pager">
                    <Paging page={page} />
                  </div>
                )}
              </div>
            );
          }}
        </ItemBrowser>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #e6e6e6;
  flex-direction: row-reverse;

  & > .products-filter {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  }

  & > .products-content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .products-breadcrumbs {
    }

    .products-toolbar {
      display: flex;
      align-items: center;
    }

    .products-search {
      height: 24px;
    }

    .products-sort {
    }

    .products-collection {
      ${AppConfig.productListing.layoutType === "grid" &&
        `
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      `}
    }

    .products-pager {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      categoryId: ownProps.pageContext.categoryId
    }),
    ActionCreator
  )(ProductListingPage)
);
